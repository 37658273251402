.language {
  position: relative;

  .language-change-btn {
     position: absolute;
     right: -26px;
     padding: 5px;
     color: rgba(0,0,0,0.5);
     cursor: pointer;

     &:hover {
      color: rgba(0,0,0,1);
     }
  }
}