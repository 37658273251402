.home-page {
  .successful-verification_container {
    margin: 15px 0;
    color: #fff;
  
    .successful-verification_title {
      font-size: 21px;
      font-weight: bold;
    }
    .successful-verification_list {
      list-style: none;
      margin: 15px 0;
      padding: 0;
    }
  }
  .home-page_sub-titles {
    margin-bottom: 15px!important;
    display: block;
  }
  .error-message {
    margin: 15px 0;
    color: #fff;
    font-size: 21px;
    font-weight: bold;
  }
}
.home-page_modal-card {
  margin-bottom: 0!important;
  padding-top: 15px;
}