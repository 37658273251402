.feedback-form {
  .feedback-form_textarea {
    width: 100%;
    resize: unset;
    font-size: 1rem;
    font-family: inherit;
    border: solid 1px rgba(0,0,0,0.4);
    margin: 1rem  0 0 0;
    border-radius: 0.2rem;
    padding: 0.5rem;

    &::-webkit-input-placeholder {
      color: rgba(0,0,0,0.6);
    }
  }
  .feedback-form_error {
    color: red;
    width: 100%;
    font-family: Arial;
    font-size: 0.75rem;
  }
}