.translated-text-field {
  position: relative;
  display: inherit;
  align-items: center;
  font-size: inherit;
  font-weight: inherit;
  overflow: visible;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

  .translated-text-field_globe {
    font-size: inherit;
    color: inherit;
    align-items: center;
    position: absolute;
    cursor: pointer;
    transition: 2s;
    z-index: 10000;
    top: 0;
    left: -1.1rem;
    overflow: visible;
    
    .translated-text-field_globe-img {
      display: flex;
      width: 1rem;
    }
  }

  .translated-text-field_form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: inherit;
    font-weight: inherit;
    overflow: visible;
    margin: 0;
    
    .translated-text-field_form-sbm-btn {
      color: inherit;
      font-size: inherit;
      z-index: 1000;
      width: 0;
      height: 0;
      position: relative;
      padding: 0;
      
      .translated-text-field_form-sbm-ico {
        z-index: 1000;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
      
    .translated-text-field_form-input {
      width: 100%;
      align-items: center;
      color: inherit;
      background-color: inherit;
      border: none;
      background-color: transparent;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      overflow: visible;
      font-family: inherit;
      
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: inherit;
        opacity: 0.5; /* Firefox */
      }
    }
  }
}