.input-container {
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 10px;
  min-height: 70px;
}
.layout-row {
  display: flex;
  flex-direction: row;
}
.layout-column {
  display: flex;
  flex-direction: column;
}
.flex {
  flex-grow: 1;
}
.justify-center {
  justify-content: center;
}
.justify-left {
  justify-content: left;
}
.justify-right {
  justify-content: right;
}
.align-center {
  align-items: center;
}
.align-left {
  align-items: left;
}
.align-right {
  align-items: right;
}
.languageIndicatior {
  text-transform: uppercase;
}