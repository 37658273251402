.table_icon {
  color: rgba(0,0,0,0.7);
  cursor: pointer;

  &:hover {
    color: rgba(0,0,0,1);
  }
}
.table-header-cell,
.table-row-cell {
  white-space: nowrap;
}