.notifications-container {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 580px;

  .notifications-container_notification-title {
    text-transform: uppercase;
  }
  .notifications-container_notification-copy-content {
    align-items: center;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;

    .notifications-container_notification-copy-button {
      cursor: pointer;
      padding: 5px 15px;
    }
  }
}